import log from 'loglevel';
import { computed, nextTick, ref, watch } from 'vue';
import { Route } from 'vue-router';

import { useRouter } from '@/composables/router/VueRouter';
import Integrations from '@/data/config/Integrations';
import { FullUserDetails } from '@/data/datatypes/UserDetails';
import { MiniApp } from '@/data/tasks/MiniApp';
import { DeployedAppWithRelease } from '@/data/version/DeployedApp';
import { useDeployedAppsStore } from '@/stores/release/DeployedApps';
import { useTasksStore } from '@/stores/Tasks';
import { useUserStore } from '@/stores/User';

type AnalyticsEventCorePayload = {
  url: string;
  matomoSiteId: number;
  referrerUrl?: string;
  appId?: string;
  appName?: string;
  pageTitle?: string;
}

export default function () {
  const router = useRouter();
  const tasksStore = useTasksStore();
  const userStore = useUserStore();
  const deployedAppsStore = useDeployedAppsStore();

  const analyticsUserInfo = ref({ userId: '', userEmail: '', forceNewVisit: false });

  const currentUserDetails = computed<FullUserDetails | null>(() => userStore.currentUserDetails);

  const unwatchUserId = watch(() => currentUserDetails.value?.id, (newId, oldId) => {
    if (newId === oldId) {
      return;
    }
    if (!newId) {
      // Expectation is that we get the ID first. So if it becomes null then everything must be cleared.
      analyticsUserInfo.value.userId = '';
      analyticsUserInfo.value.userEmail = '';
    } else {
      analyticsUserInfo.value.userId = newId;
    }
  }, { immediate: true });

  const unwatchUserEmail = watch(() => currentUserDetails.value?.email, (newEmail, oldEmail) => {
    if (newEmail === oldEmail) {
      return;
    }
    analyticsUserInfo.value.userEmail = newEmail ?? '';
    analyticsUserInfo.value.forceNewVisit = true;
  }, { immediate: true });

  const unwatchRoute = router.afterEach((to, from) => {
    // Must not send spurious analytics events. So detect if the route change is important enough...
    // But if one if these is undefined, we are clearly transitioning. So no point trying to detect
    // and silence small route changes.
    // Also checking "to" because it could be null/undefined on page closing?
    if (from !== undefined && from !== null && to !== undefined && to !== null) {
      if (to.fullPath === from.fullPath &&
        to.meta?.title === from.meta?.title &&
        to.params.tenantAppId === from.params.tenantAppId) {
        return;
      }
    }

    const newPath = to?.fullPath ? router.resolve(to.fullPath).href : undefined;
    const newUrl = newPath ? window.location.origin + newPath : undefined;
    const referrerUrl = from && from.fullPath
      ? window.location.origin + router.resolve(from.fullPath).href
      : undefined;

    const tenantAppId = (to?.params.tenantAppId ?? undefined) as string | undefined;
    const miniAppRecord: MiniApp | undefined = tasksStore.tenantApps.find((app: MiniApp) => app.id === tenantAppId);
    const appName = miniAppRecord?.name ?? tenantAppId;

    const environmentId = to?.params.environmentId ?? undefined;
    const deployedApp = deployedAppsStore.availableDeployedApps.find(app => app.environmentId === environmentId);

    const titleFromMetaInfo = to?.meta?.title;

    const matomoSiteId: number | undefined = determineSiteId(to, miniAppRecord, deployedApp);

    if (matomoSiteId && !!Integrations.ANALYTICS_SERVER) {
      // next tick to get the updated doc title
      nextTick(() => {
        const title = document.title || titleFromMetaInfo;
        let pageTitle;
        if (appName) {
          if (title) {
            pageTitle = appName + '/' + title;
          } else {
            pageTitle = appName + newPath;
          }
        } else {
          pageTitle = title || newPath;
        }

        if (newUrl) {
          processAnalyticsEvent(newUrl, matomoSiteId, referrerUrl, tenantAppId, appName, pageTitle);
        }
      });
    }
  });

  function processAnalyticsEvent(newUrl: string, matomoSiteId: number, referrerUrl?: string, appId?: string,
    appName?: string, pageTitle?: string): void {
    const userId = analyticsUserInfo.value.userId;
    const userEmail = analyticsUserInfo.value.userEmail;

    const payload = {
      url: newUrl,
      referrerUrl: referrerUrl,
      appId: appId,
      appName: appName,
      pageTitle: pageTitle,
      matomoSiteId: matomoSiteId,
    };

    // In this iteration we use the email as the identifier but it could be the email, or a hash of the email
    // or a hash of the analytics session identifier (i.e. linked to session but not traceable back to user)
    // And this could be set depending of the tenant config and their need for privacy / control.
    // If we have no identifier (guest user) Matomo will set its own user UUID.
    const userIdentifier = userEmail ?? userId;

    sendAnalyticsEvent(payload, userIdentifier);
  }

  function sendAnalyticsEvent(payload: AnalyticsEventCorePayload, userId?: string): void {
    if (analyticsUserInfo.value.forceNewVisit) {
      window._paq.push(['appendToTrackingUrl', 'new_visit=1']); // forces a new visit
    }

    window._paq.push(['setSiteId', payload.matomoSiteId]);
    window._paq.push(['setCustomUrl', payload.url]);

    if (payload.referrerUrl) {
      window._paq.push(['setReferrerUrl', payload.referrerUrl]);
    }

    if (payload.pageTitle) {
      window._paq.push(['setDocumentTitle', payload.pageTitle]);
    }

    if (userId) {
      window._paq.push(['setUserId', userId]);
    }

    window._paq.push(['trackPageView']);
    log.debug('Sent tracking event for siteId: ' + payload.matomoSiteId);

    if (analyticsUserInfo.value.forceNewVisit) {
      window._paq.push(['appendToTrackingUrl', '']); // do not force a new visit anymore
      analyticsUserInfo.value.forceNewVisit = false;
    }
  }

  function determineSiteId(to?: Route, miniAppRecord?: MiniApp, deployedApp?:
    DeployedAppWithRelease): number | undefined {
    // At present we only send analytics for fully identified apps. We don't try anymore
    // to send analytics for unknown / uncategorised events to the default analytics target (id = 1)
    // as currently don't always get the correct app info depending of the context
    // (in particular logged-in guest users).
    // This was done as part of ticket 12095.
    //
    // But, for now, kept the list of app studio excluded targets in a commented out form
    // (as, hopefully, the current situation won't last too long and will be resolved by 12087)
    //
    // const excludedTargets = [
    //   RouteNames.APP_STUDIO_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_CUSTOM_VIEW_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_DATA_SOURCE_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_WORKFLOWS_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_RULESETS_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_ANALYTICS_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_SETTINGS_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_EDIT_APP_TABLES_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_UI_FLOWS_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_CREATE_UI_FLOW_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_EDIT_UI_FLOW_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_WORKSPACE_LABELS_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_CONTEXT_CARDS_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_EDIT_CONTEXT_CARD_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_CREATE_CONTEXT_CARD_ROUTE_NAME,
    //   RouteNames.APP_STUDIO_VERSIONS_ROUTE_NAME,
    //   RouteNames.EDIT_APP_ROUTE_NAME,
    //   RouteNames.EDIT_APP_DETAILS_ROUTE_NAME,
    //   RouteNames.EDIT_APP_PERMISSIONS_ROUTE_NAME,
    //   RouteNames.EDIT_APP_WORKSPACE_LABELS_ROUTE_NAME,
    //   RouteNames.EDIT_APP_TABLES_ROUTE_NAME,
    //   RouteNames.EDIT_APP_VIEWS_ROUTE_NAME,
    //   RouteNames.EDIT_APP_UI_FLOWS_ROUTE_NAME,
    //   RouteNames.CREATE_UI_FLOW_ROUTE_NAME,
    //   RouteNames.EDIT_UI_FLOW_ROUTE_NAME,
    //   RouteNames.EDIT_APP_DATA_SOURCES_ROUTE_NAME,
    //   RouteNames.EDIT_APP_WORKFLOWS_ROUTE_NAME,
    //   RouteNames.APP_RULESETS_LIST_ROUTE_NAME,
    //   RouteNames.EDIT_APP_TRACK_TEMPLATES_ROUTE_NAME,
    //   RouteNames.APP_CONTEXT_CARDS_LIST_ROUTE,
    //   RouteNames.EDIT_APP_CUSTOM_VIEW_ROUTE_NAME,
    //   RouteNames.TRACK_EDIT_APP_ROUTE_NAME,
    //   RouteNames.TRACK_EDIT_APP_DETAILS_ROUTE_NAME,
    //   RouteNames.TRACK_EDIT_APP_TABLES_ROUTE_NAME,
    //   RouteNames.TRACK_EDIT_APP_VIEWS_ROUTE_NAME,
    //   RouteNames.TRACK_EDIT_APP_WORKFLOWS_ROUTE_NAME,
    //   RouteNames.TRACK_EDIT_APP_DATA_SOURCES_ROUTE_NAME
    // ];

    // const toName: string = (to?.name ?? '') as string;
    // if (excludedTargets.includes(toName)) {
    //   // Record App studio edits against the default virtual website
    //   return 1;
    // }

    if (deployedApp) {
      return deployedApp.analyticsSiteId;
    }

    if (miniAppRecord && miniAppRecord.matomoSiteId && miniAppRecord.analyticsEnabled) {
      return miniAppRecord.matomoSiteId;
    }

    // For now, don't track anything unless we have clearly identified the app
    // and it had analytics activated...
    return undefined;
  }

  function unwatch() {
    unwatchUserId();
    unwatchUserEmail();
    unwatchRoute();
  }

  return {
    unwatch
  };
}
